import React from 'react';
import logo from './images/black-logo.png';
import favicon from './images/favicon-128.png';
import './main.css';
import {Helmet} from 'react-helmet';
import ReCAPTCHA from "react-google-recaptcha";
import {withAlert} from 'react-alert';
const axios = require("axios");

const recaptcha_key = "6LdcFfAUAAAAAAU4Wu2nvWuLftiyT23b_1c4Z_aQ";

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            subject: '',
            description: '',
            campus: '',
            studentname: '',
            grade: '',
            type: '',
            form_shown: true,
            loading: false
        };
        this.captcha_checked = false;
    }

    onChange(event) {
        let state = this.state;
        state[event.target.name] = event.target.value;
        this.setState(state);
    }

    captchaComplete(event) {
        this.captcha_checked = true;
    }

    handleSubmit(event) {
        event.preventDefault();
        if (!this.captcha_checked) {
            this.showError("Please check captcha box.");
            return false;
        }
        if (this.state.email === '') {
            this.showError("Please specify email.")
            return false;
        }
        let emailValid = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if (!emailValid.test(this.state.email)) {
            this.showError("Email is not formatted correctly.")
            return false;
        }

        if (this.state.subject === '') {
            this.showError("Please specify subject.");
            return false;
        }

        if (this.state.campus === '') {
            this.showError('Please specify campus.');
            return false;
        }

        if (this.state.studentname === '') {
            this.showError('Please specify student name.');
            return false;
        }

        if (this.state.grade === '') {
            this.showError("Please specify grade.");
            return false;
        }

        this.setState({
            ...this.state,
            loading: true
        });
        axios.post("https://cs-freshdesk-proxy-three-one.azurewebsites.net/api/CreateTicketProxy",
            {
               email: this.state.email,
               subject: this.state.subject,
               description: this.state.description + "<br />Ticket Type: " + this.state.type,
               status: 2,
               priority: 1,
               custom_fields: {
                   cf_campus: this.state.campus,
                   cf_student_name: this.state.studentname,
                   cf_grade: this.state.grade
               }
            }).then(response => {
                this.setState({
                    ...this.state,
                    loading: false,
                    form_shown: false
                });
            }
         );
        console.log(this.captcha_checked);
        return false;
    }

    showError(msg) {
        this.props.alert.show(msg, {
            timeout: 5000,
            type: 'error'
        });
    }

    render() {
        return (
            <div className="App container mx-auto m-5 flex flex-wrap items-baseline">
                <Helmet>
                    <title>Submit Support Ticket</title>
                    <link rel="icon" type="image/png" href={favicon} sizes="128x128" />
                </Helmet>
                {this.state.loading &&
                    <div className="loading"></div>
                }
                <div className="w-1/3">
                    <img src={logo} alt="challenger-logo" className="ml-5 mr-5 mt-5 inline-block " />
                </div>
                { this.state.form_shown &&
                <div className="w-full">
                    <form onSubmit={event => this.handleSubmit(event)} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-wrap justify-start ">
                        <div className="w-full text-xl md:w-1/3 mb-5">
                            <h1>Submit Support Ticket</h1>
                        </div>
                        <div className="w-full">
                            <label htmlFor="email-input" className="mr-2">Email</label>
                            <input value={this.state.email} onChange={event => this.onChange(event)} id="email-input" className="shadow appearance-none border border-gray rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" name="email" />
                        </div>
                        <div className="w-full mt-3">
                            <label htmlFor="subject-input" className="mr-2">Subject</label>
                            <input value={this.state.subject} onChange={event => this.onChange(event)} id="subject-input" className="shadow appearance-none border border-gray rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" name="subject" />
                        </div>
                        <div className="w-full mt-3">
                            <label htmlFor="description-input" className="mr-2">Description</label>
                            <textarea value={this.state.description} onChange={event => this.onChange(event)} rows='4' className="p-3 shadow appearance-none border border-gray rounded w-full" name="description" />
                        </div>
                        <div className="w-full mt-3">
                            <label htmlFor="campus-input" className="mr-2">Campus</label>
                            <select  value={this.state.campus} onChange={event => this.onChange(event)} id="subject-input"
                                     className="shadow appearance-none border border-gray rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                     name="campus">
                                <option value="">Choose Campus</option>
                                <option>Almaden</option>
                                <option>Ardenwood</option>
                                <option>Berryessa</option>
                                <option>Harwood</option>
                                <option>Middlefield</option>
                                <option>Newark</option>
                                <option>Saratoga</option>
                                <option>Shawnee</option>
                                <option>Strawberry Park</option>
                                <option>Sunnyvale</option>
                                <option>Boise Bloom</option>
                                <option>Everest</option>
                                <option>Desert Hills</option>
                                <option>Lone Mountain</option>
                                <option>Los Prados</option>
                                <option>Silverado</option>
                                <option>Avery Ranch</option>
                                <option>Legacy</option>
                                <option>Pond Springs</option>
                                <option>Round Rock</option>
                                <option>Farmington</option>
                                <option>Holladay</option>
                                <option>Salt lake Preschool</option>
                                <option>Salt lake Elementary</option>
                                <option>Sandy Preschool</option>
                                <option>Sandy Elementary</option>
                                <option>Traverse Mountain</option>
                                <option>West Jordan</option>
                            </select>
                        </div>
                        <div className="w-full mt-3">
                            <label htmlFor="student-name-input" className="mr-2">Student Name</label>
                            <input value={this.state.studentname} onChange={event => this.onChange(event)} id="subject-input"
                                   className="shadow appearance-none border border-gray rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                   type="text" name="studentname"/>
                        </div>
                        <div className="w-full mt-3">
                            <label htmlFor="grade-input" className="mr-2">Grade</label>
                            <select value={this.state.grade} onChange={event => this.onChange(event)} id="grade-input"
                                    className="shadow appearance-none border border-gray rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    name="grade">
                                <option value="">Choose Grade</option>
                                <option>Preschool</option>
                                <option>Kindergarten</option>
                                <option>1st Grade</option>
                                <option>2nd Grade</option>
                                <option>3rd Grade</option>
                                <option>4th Grade</option>
                                <option>5th Grade</option>
                                <option>7th Grade</option>
                                <option>8th Grade</option>
                            </select>
                        </div>
                        <div className="w-full mt-3">
                            <label htmlFor="type-input" className="mr-2">Type</label>
                            <select value={this.state.type} onChange={event => this.onChange(event)} id="type-input"
                                    className="shadow appearance-none border border-gray rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    name="type">
                                <option>Choose Type</option>
                                <option>Curriculum Question</option>
                                <option>Technical Support</option>
                           </select>
                        </div>
                        <div className="w-full mt-3">
                            <ReCAPTCHA
                                sitekey={recaptcha_key}
                                onChange={() => this.captchaComplete()}
                            />
                        </div>
                        <div className="w-full mt-3">
                            <input type="submit" className="bg-red px-5 py-3 rounded text-white cursor-auto focus:shadow-outline cursor-pointer" value="Submit Ticket" />
                        </div>
                    </form>
                </div>
                }
                { !this.state.form_shown &&
                    <div className="w-full">
                        <div className="flex flex-wrap justify-center">
                            <p>Thank you for submitting a ticket.</p>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default withAlert()(App);
